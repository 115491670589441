// @flow

import React, { Fragment, useState } from 'react';
import type { Node } from 'react';
import { useMutation } from '@apollo/client';
import { useSelector } from 'react-redux';
import { useIntl, FormattedMessage } from 'react-intl';
import { calcPercentDifference } from '@riseart/fe-utils';
import { Row, Col } from '@riseart/antd-provider';
import { CardTile } from '@riseart/card';
import { Section, Wrapper } from '@riseart/layout';
import { ArtActionCheckout } from '@riseart/art';
import { Heading, Link, Button, LazyloadPlaceholder } from '@riseart/common';
import { artDirection as CONFIG_ART_DIRECTION } from 'Config';
import { offer as OFFER_ENUM } from 'Enum';
import { useQuery } from 'shared_services/apollo/useQuery';
import {
  delay,
  formatCurrency,
  getStoreCodeByCurrencyCode,
} from 'shared_services/riseart/utils/Utils';
import { UrlAssembler } from 'shared_services/riseart/utils/UrlAssembler';
import { selectUserId } from 'shared_services/redux/selectors/user';
import { useLocale } from 'shared_services/redux/hooks/useLocale';
import { LayoutDefault } from 'shared_components/layouts/Default';
import { Breadcrumbs } from 'shared_components/common/breadcrumbs/Breadcrumbs';
import { PageHeadWrapper } from 'shared_components/common/sections/PageHead';
import { ConfirmationModal } from 'shared_components/common/modal/Confirmation';
import { IsomorphicRipple } from 'shared_components/common/preloader/IsomorphicRipple';
import { generatePicturePropsForCDN } from 'shared_components/common/artdirection/picture/Picture';
import { extractImageFileFromData } from 'shared_services/riseart/utils/ImageUtils';
import { AddCartItemButton } from 'shared_data/providers/queries/AddCartItem';
import { ART_DIRECTION_SIZES } from 'shared_models/ArtDirectionSizes';
import READ_USER_OFFER from 'shared_data/queries/user/readOffer.graphql';
import ACTION_OFFER from 'shared_data/queries/offer/action.graphql';

/**
 * getOfferPicturePropsFromImages
 *
 * @param {Object} data
 * @param {boolean} useLazyload
 * @param {Object} artDirectionSizes
 * @param {Object}
 */
export function getOfferPicturePropsFromImages(
  art: Object,
  imgType: string,
  useLazyload?: boolean = false,
  lazyloadHeight: number = 270,
  artDirectionSizes: Object = ART_DIRECTION_SIZES.offer.details,
): Object {
  const { title, images } = art || {};
  const artImage = extractImageFileFromData.artImage(images, imgType);

  return {
    ...(artImage &&
      generatePicturePropsForCDN({
        image: artImage,
        sizesList: artDirectionSizes,
        artDirectionConfig: CONFIG_ART_DIRECTION.art.main,
      })),
    alt: title,
    image: {
      width: artImage.width,
      height: artImage.height,
    },
    lazyload: useLazyload
      ? {
          height: lazyloadHeight,
          offset: 200,
          placeholder: <LazyloadPlaceholder height={lazyloadHeight} />,
        }
      : null,
  };
}

/**
 * MeOfferDetails
 *
 * @param {Object} props
 */
export function MeOfferDetails({ match }: Object): Node {
  const DATE_FORMAT_OPTIONS = {
    year: 'numeric',
    month: 'short',
    day: '2-digit',
    weekday: 'short',
  };
  const { formatMessage, formatDate } = useIntl();
  const [loading, setLoading] = useState(false);
  const locale = useLocale();
  const userId = useSelector(selectUserId);
  const listTitle = formatMessage({ id: 'components.me.offer.listTitle' });
  const [offerAction, { loading: loadingOfferAction }] = useMutation(ACTION_OFFER);
  const {
    data,
    loading: offerLoading,
    refetch,
  } = useQuery(READ_USER_OFFER, {
    variables: { offerId: match.params.id, userId },
    fetchPolicy: 'network-only',
    skip: !userId,
  });
  const { readUserOffer: offer } = data || {};
  const { art } = offer || {};
  const title =
    (art && art.title) ||
    (userId &&
      offer &&
      !offerLoading &&
      formatMessage({ id: 'components.me.offer.title' }, { sku: offer.productSku })) ||
    null;
  const offerAmount = offer ? offer.offerAgreed || offer.offerAmount : null;
  const offerStoreCode =
    offer && offer.offerCurrencyCode ? getStoreCodeByCurrencyCode(offer.offerCurrencyCode) : null;

  const actionOfferHandler =
    (mutation: Object, action: string, id: number, refetchOffer: Object) => (handleClose) => {
      setLoading(true);
      mutation({
        variables: {
          id,
          action,
        },
      })
        .then(() => {
          delay(() =>
            refetchOffer()
              .then(() => {
                setLoading(false);
                handleClose();
              })
              .catch(() => setLoading(false)),
          );
        })
        .catch(() => setLoading(false));
    };

  return (
    <LayoutDefault>
      <IsomorphicRipple isActive={(loading || offerLoading) && !loadingOfferAction}>
        <Section padding="none">
          <Wrapper>
            <Breadcrumbs
              links={[
                {
                  name: listTitle,
                  url: UrlAssembler.byRouteKey('myOffers'),
                },
                ...(title ? [{ isLink: false, name: title }] : []),
              ]}
            />
          </Wrapper>
          <PageHeadWrapper>
            {title ? (
              <Heading tag="h2" align="center">
                {formatMessage({ id: 'components.me.offer.offerTitle' }, { title })}
              </Heading>
            ) : null}
          </PageHeadWrapper>
        </Section>
        {offer ? (
          <Section>
            <Wrapper>
              <Row type="flex" justify="center">
                <Col xs={24} lg={16}>
                  <CardTile
                    displayStyle="mainLarge"
                    title={title}
                    url={art ? UrlAssembler.artDetail(art.id, art.slug) : null}
                    actionButtons={
                      art
                        ? [
                            ...(offer.status === OFFER_ENUM.status.ACTIVE &&
                            art.productCanBuy &&
                            offer.sku &&
                            offer.sku.id
                              ? [
                                  <FormattedMessage id="components.art.add_to_basket">
                                    {(btnText: string) => (
                                      <AddCartItemButton cartId="buy" qty={1} skuId={offer.sku.id}>
                                        {({ loading, onClick }) => (
                                          <ArtActionCheckout
                                            onClick={onClick}
                                            loading={loading}
                                            buttonText={btnText}
                                          />
                                        )}
                                      </AddCartItemButton>
                                    )}
                                  </FormattedMessage>,
                                ]
                              : []),
                            ...(offer.canCancel
                              ? [
                                  <FormattedMessage id="components.me.offer.cancel.title">
                                    {(title: string) => (
                                      <FormattedMessage id="components.me.offer.cancel.action">
                                        {(btnText: string) => (
                                          <ConfirmationModal
                                            title={title}
                                            loading={loadingOfferAction}
                                            body={() => (
                                              <div>
                                                {formatMessage({
                                                  id: 'components.me.offer.cancel.description',
                                                })}
                                              </div>
                                            )}
                                            onConfirm={actionOfferHandler(
                                              offerAction,
                                              OFFER_ENUM.action.CANCEL,
                                              offer.id,
                                              refetch,
                                            )}
                                          >
                                            <Button
                                              type="secondary"
                                              width="container"
                                              size="medium"
                                              title={btnText}
                                            >
                                              {btnText}
                                            </Button>
                                          </ConfirmationModal>
                                        )}
                                      </FormattedMessage>
                                    )}
                                  </FormattedMessage>,
                                ]
                              : []),
                          ]
                        : null
                    }
                    author={
                      art && art.artist ? (
                        <Fragment>
                          {' '}
                          {formatMessage(
                            { id: 'common.byNameLowercase' },
                            {
                              name: (
                                <Link
                                  to={UrlAssembler.artistProfile(art.artist.id, art.artist.alias)}
                                  title={art.artist.name}
                                >
                                  {art.artist.name}
                                </Link>
                              ),
                            },
                          )}
                        </Fragment>
                      ) : null
                    }
                    details={formatMessage({
                      id:
                        offer.status === OFFER_ENUM.status.INACTIVE && offer.state
                          ? `components.me.offer.state.${offer.state}`
                          : `components.me.offer.status.${offer.status}`,
                    })}
                    price={
                      <Fragment>
                        <s>
                          {formatCurrency(offer.productPrice, offerStoreCode, undefined, {
                            locale: locale && locale.name,
                          })}
                        </s>
                        &nbsp;&nbsp;
                        <span>
                          {formatCurrency(offerAmount, offerStoreCode, undefined, {
                            locale: locale && locale.name,
                          })}
                        </span>
                      </Fragment>
                    }
                    discount={`(${formatMessage(
                      {
                        id: 'components.me.offer.percentDiscount',
                      },
                      {
                        discount: `${calcPercentDifference(offerAmount, offer.productPrice)}%`,
                      },
                    )})`}
                    additional={
                      offer.created || offer.expiryDate ? (
                        <Fragment>
                          {offer.created
                            ? formatMessage(
                                { id: 'components.me.offer.created' },
                                {
                                  date: formatDate(offer.created, DATE_FORMAT_OPTIONS),
                                },
                              )
                            : null}
                          {offer.created && offer.expiryDate ? <br /> : null}
                          {offer.expiryDate
                            ? formatMessage(
                                { id: 'components.me.offer.expires' },
                                {
                                  date: formatDate(offer.expiryDate, DATE_FORMAT_OPTIONS),
                                },
                              )
                            : null}
                        </Fragment>
                      ) : null
                    }
                    pictureProps={getOfferPicturePropsFromImages(
                      art,
                      'TYPE_MAIN_SQUARE',
                      true,
                      270,
                    )}
                  />
                </Col>
              </Row>
            </Wrapper>
          </Section>
        ) : null}
      </IsomorphicRipple>
    </LayoutDefault>
  );
}
